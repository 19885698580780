.Toastify__toast-container {
  --toastify-color-error: #ea1a4c;
  --toastify-color-success: #68b15c;
  --toastify-toast-width: 350px;
  --toastify-toast-min-height: 45px;
  min-width: var(--toastify-toast-width);
  width: auto !important;
  .Toastify__toast {
    padding: 0 20px;
  }
  .Toastify__toast--error {
    background-color: var(--toastify-color-error);
  }
  .Toastify__toast--success {
    background-color: var(--toastify-color-success);
  }
  .Toastify__toast--error,
  .Toastify__toast--success {
    color: var(--toastify-color-light);
    .Toastify__close-button--light {
      color: var(--toastify-color-light);
      opacity: 1;
    }
  }
  .Toastify__toast-body {
    padding-left: initial;
    padding-right: initial;
    font-weight: 500;
    font-size: 1rem;
    p {
      margin: auto;
    }
  }
  .Toastify__close-button {
    margin: auto auto auto 20px;
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}
