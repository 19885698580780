#checkoutForm{padding:20px;}

.flex-50{flex: 1 0;}
.flex-75{flex: 2 75%;}
.flex-25{flex: 1 0;}

.stripe-con{border-bottom: 1px solid #fff !important;padding-bottom:4px;background-color: transparent !important}
.current-price{ font-size:2rem; }
.small{font-size: .875rem;}
.smaller{font-size: .75rem;}
.x-small{font-size: .625rem;}
h2, .h2 {
  font-weight: 600;
}



input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
hr {
  border: 0;
  border-top: 1px solid #E0E0E0;
}
a {
  color: inherit;
  text-decoration: none;
  &:hover { text-decoration: underline; }
}