.code-container {
  width: 486px;
  height: 100px;
  border: 1px solid #becad8;
  border-radius: 20px;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  gap: 20px;
  padding: 0 20px;
  &__number {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 50px;
    border: none;
    border-bottom: 4px solid #becad8;
    margin: 15px 0;
    color: inherit;
    font-size: 4rem;
    font-weight: 600;
    &:focus-visible {
      outline: none;
    }
  }
  &__divider {
    content: "'";
    background-color: #254778;
    width: 21px;
    height: 6px;
    align-self: center;
  }
}
@media screen and (max-width: 576px) {
  .code-container {
    width: auto;
    height: auto;
    gap: 10px;
    padding: 0 10px;
    &__number {
      width: 10%;
      font-size: 2rem;
    }
    &__divider {
      width: 15px;
      height: 4px;
    }
  }
}